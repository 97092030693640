<template>
  <div>
    <q-form ref="editForm">
      <!-- 개정 -->
      <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="isOld">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-1">
            <!-- 개정번호 -->
            <c-text
              :required="editable && rev.isRevision"
              :editable="editable && rev.isRevision"
              label="개정번호"
              name="revisionNum"
              v-model="sop.revisionNum">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2">
            <!-- 개정일시 -->
            <c-text
              :editable="false"
              label="개정일시"
              name="regDtStr"
              v-model="sop.regDtStr">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-1">
            <!-- 개정자 -->
            <c-text
              :editable="false"
              label="개정자"
              name="regUserName"
              v-model="sop.regUserName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <!-- MOC 번호 -->
            <c-moc 
              :editable="editable && rev.isRevision"
              :isSubmit="saveCallData"
              :document.sync="sop"
              :documentId="popupParam.mdmSopId"
              documentTitle="diagramTitle"
              label="MOC번호"
              name="sopMocId"
              v-model="sop.sopMocId"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <!-- 개정사유 -->
            <c-text
              :required="editable && rev.isRevision"
              :editable="editable && rev.isRevision"
              label="개정사유"
              name="revisionContent"
              v-model="sop.revisionContent">
            </c-text>
          </div>
        </template>
      </c-card>
      <c-card title="SOP 정보" class="cardClassDetailForm">
        <template slot="card-select">
          <!-- 개정이력 -->
          <c-select
            v-if="isOld"
            :editable="editable&&!rev.isRevision"
            :comboItems="sop.revs"
            type="custom"
            typetext="개정이력"
            itemText="revisionNum"
            itemValue="mdmSopId"
            label=""
            name="revSelectValue"
            v-model="sop.revSelectValue"
            @input="getRevVersion"
          ></c-select>
        </template>
        <template slot="card-button">
          <q-btn-group outline >
            <c-qr-btn
              v-if="editable && isOld && !rev.isRevision"
              :mobileUrl="'/mdm/pim/sopManage?mdmSopId='+popupParam.mdmSopId"
            />
            <c-btn
              v-show="editable && isOld && !rev.isRevision"
              label="개정"
              icon="restart_alt"
              @btnClicked="setRevision" />
            <c-btn
              v-show="editable && isOld && rev.isRevision"
              label="개정취소"
              icon="restart_alt"
              @btnClicked="cancelRevision" />
            <c-btn
              v-if="editable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="sop"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveSopInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :editable="editable"
              label="안전작업표준 작업명"
              name="sopName"
              v-model="sop.sopName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-text
              :editable="editable"
              label="안전작업표준 관리번호"
              name="sopNo"
              v-model="sop.sopNo">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-process
              :required="true"
              :editable="editable"
              label="단위공정"
              name="subProcessCd"
              v-model="sop.subProcessCd">
            </c-process>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="WORK_CLASS_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="workClassCd"
              label="작업구분"
              v-model="sop.workClassCd"
            ></c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-dept-multi 
              label="관리부서" 
              :parentCheckDepts="sop.managementDepts" 
              :plantCd="sop.plantCd" 
              name="managementDepts" 
              v-model="sop.managementDepts" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="WORK_TYPE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="workTypeCd"
              label="작업유형"
              v-model="sop.workTypeCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="WORK_KIND_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="workKindCd"
              label="작업형태"
              v-model="sop.workKindCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="sop.plantCd" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="사용여부"
              name="useFlag"
              v-model="sop.useFlag"
            />
          </div>
        </template>
      </c-card>
      <div style="height:0">
        <q-banner v-if="rev.isRevision" inline-actions class="text-white bg-red sop-step-table-info-banner">
          <template v-slot:avatar>
            <q-icon name="info_outline" color="white" />
          </template>
          {{$language('개정시에 `작업단계 > 유해위험요인 > 원인` 정보는 이전 버전 정보를 사용하게 됩니다.')}}
        </q-banner>
      </div>
      <c-table
        v-if="isOld"
        ref="table"
        title="작업절차"
        :gridHeight="setSopStepheight"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="sop.sopStepHazards"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :disabled="rev.isRevision"
        :editable="editable&&!rev.isRevision"
        :isExcelDown="false"
        :isFullScreen="false"
        selection="multiple"
        rowKey="jobStepId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable&&!rev.isRevision" label="추가" icon="add" @btnClicked="addSopStep" />
            <c-btn v-if="editable&&!rev.isRevision" label="삭제" icon="remove" @btnClicked="deleteSopStep" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'sopStep'">
            <q-item class="card-sop-step-list">
              <q-item-section thumbnail>
                <img :src="props.row['sopStepDownPath']" style="height: 80px;width: 80px;" 
                  :class="{ 'cursor-pointer': Boolean(props.row.sopStepSysAttachFileId) }"
                  @click.prevent="setPreview(props.row.sopStepSysAttachFileId)" />
              </q-item-section>
              <q-item-section>
                <q-chip 
                  dense outline square 
                  color="teal" 
                  text-color="white" 
                  style="max-width: 60px;"
                  :label="`# ${props.row.jobStepNo}`"
                  @click.stop="openSopStepDetail(props.row.jobStepId)" />
                <q-item-label class="sopStep-card-title">
                  <q-btn
                    class="tableinnerBtn"
                    flat
                    :align="col.align"
                    color="blue-6"
                    :label="props.row['jobStepName']"
                    @click.stop="openSopStepDetail(props.row.jobStepId)" />
                </q-item-label>
                <q-item-label caption>
                  {{props.row['jobStepDesc']}}
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <template v-else-if="col.name === 'hazard'">
            <q-item v-if="props.row.sopStepHazardId" class="card-sop-step-list">
              <q-item-section>
                <q-item-label class="sopStep-card-title">
                  {{`${props.row['ramRiskHazardClassName']} / ${props.row['riskHazardName']}`}}
                </q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </template>
      </c-table>
    </q-form>

    <!-- 이미지 미리보기 -->
    <q-dialog v-if="preview&&preview.src" 
      v-model="preview.isShow">
      <q-card class="preview-image-card">
        <q-img :src="preview.src"></q-img>
        <q-card-section>
          <q-btn
            fab
            color="primary"
            icon="save_alt"
            class="absolute"
            style="top: 0; right: 12px; transform: translateY(-50%);"
            @click.prevent="fileDown(preview)"
          ></q-btn>
          <div class="row no-wrap items-center">
            <div class="col text-h6">
              {{preview.oriFileNm}}
            </div>
            <div class="col-auto text-grey text-caption q-pt-md row no-wrap items-center">
              {{ $comm.bytesToSize(preview.fileSize) }}
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sopInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
      }),
    },
    rev: {
      type: Object,
      default: () => ({
        isRevision: false,
      }),
    },
    sop: {
      type: Object,
      default: () => ({
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        sopMocId: '',
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
        sopStepHazards: [], // 작업단계 (w/h. 유해위험요인)
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'jobStepId' },
          { index: 1, colName: 'sopStepHazardId' },
          { index: 4, colName: 'jobStepId' },
          { index: 5, colName: 'jobStepId' },
          { index: 6, colName: 'jobStepId' },
        ],
        columns: [
          {
            name: 'sopStep',
            field: 'sopStep',
            label: '작업단계',
            align: 'left',
            sortable: false,
            type: 'custom',
            style: 'width: 300px'
          },
          {
            name: 'hazard',
            field: 'hazard',
            label: '분류/유해위험요인',
            align: 'left',
            sortable: false,
            type: 'custom',
            style: 'width: 450px'
          },
          {
            name: 'hazardOccurReason',
            field: 'hazardOccurReason',
            label: '원인',
            align: 'left',
            sortable: false,
            style: 'width: 100px'
          },
          {
            name: 'hazardOccurResult',
            field: 'hazardOccurResult',
            label: '결과',
            align: 'left',
            sortable: false,
            style: 'width: 100px'
          },
          {
            name: 'caution',
            field: 'caution',
            label: '주의사항',
            align: 'left',
            sortable: false,
            style: 'width: 150px'
          },
          {
            name: 'saftyShied',
            field: 'saftyShied',
            label: '안전보호구',
            align: 'left',
            sortable: false,
            style: 'width: 120px'
          },
          {
            name: 'useTool',
            field: 'useTool',
            label: '사용공구',
            align: 'left',
            sortable: false,
            style: 'width: 120px'
          },
        ],
      },
      sopStepDialog: {
        jobStepId: '',  // 작업단계일련번호
        mdmSopId: '',  // 안전작업 표준 일련번호
        jobStepNo: '',  // 작업단계 번호
        jobStepName: '',  // 작업단계 명
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        show: false
      },
      preview: {
        isShow: false,
        sysAttachFileId: '',
        src: '',
        oriFileNm: '',
        fileSize: 0,
        fileExt: '',
      },
      saveCallData: '',
      serverName: '',
      editable: true,
      isSave: false,
      isSaveSopStep: false,
      mappingType: 'POST',
      saveUrl: '',
      sopStepHazardListUrl: '',
      deleteSopStepUrl: '',
      revVersionUrl: '',
      previewUrl: '',
      fileDownUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    selectSopStep() {
      return Boolean(this.sopStep.jobStepId)
    },
    setSopStepheight() {
      let height = this.contentHeight - 200;
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
      this.sopStepHazardListUrl = selectConfig.mdm.sop.sopStepHazard.url;
      this.deleteSopStepUrl = transactionConfig.mdm.sop.step.delete.url;
      this.revVersionUrl = selectConfig.com.revision.getversion;
      this.previewUrl = selectConfig.com.upload.preview.url
      this.fileDownUrl = selectConfig.com.upload.fileDown.url
      // code setting
      // list setting
    },
    getSopStepList() {
      if (this.isOld) {
        this.$http.url = this.sopStepHazardListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          mdmSopId: this.popupParam.mdmSopId
        };
        this.$http.request((_result) => {
          this.$set(this.sop, 'sopStepHazards', _result.data)
          this.sop.regDtStr = this.sop.regDtStr.slice(0, 19)
        },);
      }
    },
    saveSopInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.mdm.sop.job.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.rev.isRevision) {
        saveMsg = '현재버전을 개정하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.sop.regUserId = this.$store.getters.user.userId;
              this.sop.chgUserId = this.$store.getters.user.userId;

              // 개정시
              if (this.rev.isRevision) {
                this.saveUrl = transactionConfig.mdm.sop.job.insert.url;
                this.mappingType = 'POST';
                this.sop.sysRevision = Number(this.sop.sysRevision) + 1;
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.mdmSopId = result.data;
      this.saveCallData = uid();
      this.rev.isRevision = false;
      this.$emit('getDetail')
    },
    setRevision() {
      this.$http.url = this.revVersionUrl;
      this.$http.param = {
        revisionNum: this.sop.revisionNum
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.sop, {
          revisionNum: _result.data,
          revisionContent: '',
          regDtStr: '',
          regUserName: '',
        });
        this.rev.isRevision = true;
      });
    },
    cancelRevision() {
      this.rev.isRevision = false;
      this.$emit('getDetail')
    },
    getRevVersion(mdmSopId) {
      if (mdmSopId) {
        this.$emit('getDetail', mdmSopId)
      }
    },
    deleteSopStep() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteSopStepUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.sop.sopStepHazards = this.$_.reject(this.sop.sopStepHazards, { jobStepId: item.jobStepId });
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addSopStep() {
      this.openSopStepDetail();
    },
    openSopStepDetail(jobStepId) {
      this.popupOptions.title = '작업단계 상세';
      this.popupOptions.param = {
        mdmSopId: this.popupParam.mdmSopId,
        jobStepId: jobStepId
      };
      this.popupOptions.target = () => import(`${'./sopStepInfo.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopStepPopup;
    },
    closeSopStepPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getSopStepList();
    },
    setPreview(sysAttachFileId) {
      if (!sysAttachFileId) return;
      // 이미지인  경우
      this.$http.url = this.previewUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysAttachFileId: sysAttachFileId,
      };
      this.$http.request(
        _result => {
          this.preview.isShow = true;
          this.preview.sysAttachFileId = sysAttachFileId;
          this.preview.src = 'data:' + ';base64,' + _result.data ;
        },
      );
    },
  }
};
</script>
<style lang="sass">
.sopStep-card-title
  margin-left: -5px
  font-size: 0.8rem
.card-sop-step-list
  padding: 0px 16px !important
  min-height: 28px
.sop-step-table-info-banner
  position: relative
  top: 150px
  left: 50%
  transform: translate(-50%)
  width: 700px
  z-index: 1000
</style>